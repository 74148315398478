import React from 'react';

import './assets/css/contact.css';
import contactSrc from './assets/images/contat-image.webp';
import ReCAPTCHA from 'react-google-recaptcha';
import { validateToken, sendInquiries } from './service';

class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.contactImage = new Image().src = contactSrc;
    this.recaptchaRef = React.createRef();
    this.formRef= React.createRef();
    this.onSubmitWithReCAPTCHA = this.onSubmitWithReCAPTCHA.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleError = this.handleError.bind(this);
    this.resetRecaptcha = this.resetRecaptcha.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = {
      name: '',
      phone: '',
      date: '',
      location: '',
      email: '',
      message: '',
      displaySuccess: false,
      displayError: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.recaptchaRef.current.explicitRender();
  }


  componentWillUnmount() {
    this.recaptchaRef.current.reset();
  }


  onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();
    const token = await this.recaptchaRef.current.executeAsync();
    await validateToken(token).then(() => {
       this.submitForm(e);
    }).catch(reason => {
      this.handleError(reason);
    });
    this.resetRecaptcha();
  };


  submitForm = async (e) => {
    e.preventDefault();
    this.setState({
      displaySuccess: false,
      displayError: false,
      isLoading: true
    })
    await sendInquiries(JSON.stringify(this.state))
      .then(() => {
        this.formRef.current.reset()
        this.setState({
          displaySuccess: true,
          isLoading: false,
          name: '',
          phone: '',
          date: '',
          location: '',
          email: '',
          message: '',
        })
      })
      .catch(reason => {
        this.handleError(reason);
      });
  };

  resetRecaptcha() {
    this.recaptchaRef.current.reset();
  }

  handleOnChange(e) {
    let name = e.target.getAttribute('name');
    this.setState({ [name]: e.target.value });
  }

  handleError(reason) {
    console.error(reason)
    this.setState({
      displayError: true,
      isLoading: false
    })
  }


  render() {
    return (
      <div className="container-fluid disable-margin-padding-container overflow-x-hidden">
        <section className="contact-section">
          <main className="contact-section__main">
            <div className="row no-margin-row no-padding-row">
              <div className="col-sm padding__5 wrapper-from-bottom">
                <div className="contact-title-container text-center">
                  <h1 className="contact-title">Book a Production Truck now!</h1>
                  <br/>
                  <h2 className="contact-description">Please enter the necessary information in order to book this
                    service.</h2>
                </div>
                <br/>
                <div className="formbold-main-wrapper">
                  <div className="formbold-form-wrapper">
                    <form className="form-group required" onSubmit={this.onSubmitWithReCAPTCHA} ref={this.formRef}>
                      <div className="flex flex-wrap formbold--mx-3">
                        <div className="w-full sm:w-half formbold-px-3">
                          <div className="formbold-mb-5 w-full">
                            <label htmlFor="name" className="formbold-form-label control-label"> Full Name </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Your Name or Company Name"
                              className="formbold-form-input"
                              onChange={this.handleOnChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full sm:w-half formbold-px-3">
                          <div className="formbold-mb-5">
                            <label htmlFor="phone" className="formbold-form-label"> Phone Number </label>
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder="Enter your phone number"
                              className="formbold-form-input"
                              onChange={this.handleOnChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap formbold--mx-3">
                        <div className="w-full sm:w-half formbold-px-3">
                          <div className="formbold-mb-5 w-full">
                            <label htmlFor="date" className="formbold-form-label control-label"> Date </label>
                            <input
                              type="date"
                              name="date"
                              id="date"
                              className="formbold-form-input"
                              onChange={this.handleOnChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full sm:w-half formbold-px-3">
                          <div className="formbold-mb-5">
                            <label htmlFor="location" className="formbold-form-label control-label"> Location </label>
                            <input
                              type="text"
                              name="location"
                              id="location"
                              placeholder="Please choose your location"
                              className="formbold-form-input"
                              onChange={this.handleOnChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="formbold-mb-5">
                        <label htmlFor="email" className="formbold-form-label control-label"> Email Address </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter your email"
                          className="formbold-form-input"
                          onChange={this.handleOnChange}
                          required
                        />
                      </div>
                      <div className="formbold-mb-5 formbold-pt-3">
                        <label htmlFor="message" className="formbold-form-label control-label">
                          Your message
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          className="formbold-form-input"
                          onChange={this.handleOnChange}
                          required
                        />
                      </div>

                      <div className="text-center">
                        {
                          this.state.displaySuccess &&
                            <div className="alert alert-success" role="alert">
                              Ob Truck booking request is successfully sent
                            </div>
                        }
                        {
                          this.state.displayError &&
                            <div className="alert alert-danger" role="alert">
                              Something went wrong, we apologise for inconvenience, please use contact information below.
                            </div>
                        }
                        <button className="formbold-btn" type="submit">
                          {
                            this.state.isLoading &&
                              <span>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span>Loading...</span>
                              </span>
                          }
                          {
                            !this.state.isLoading &&
                            <span>Book Appointment</span>
                          }
                        </button>
                      </div>
                      <div className="display-flex flex-wrap justify-content-flex-end">
                        <div className="col-sm flex-50pre">
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey="6LfHLKsjAAAAABD814eQ6D_Ad1ZUCDZwbBYGeCgB"
                            size="invisible"
                            onExpired={this.resetRecaptcha}
                            onErrored={this.resetRecaptcha}
                          />
                        </div>
                      </div>


                    </form>
                  </div>
                </div>
              </div>
              <div className="col-sm padding__5 text-center">
                <img className="img-fluid" src={this.contactImage} alt={'Visual Impact OB Truck Contact'}/>
              </div>
            </div>
            <div className="row no-margin-row no-padding-row">
              <div className="col-sm padding__5">
                <div className="contact-info">
                  <p className="contact-info-title"><strong>Contact us via phone or email</strong></p>
                  <hr/>
                  <p className="contact-info-data">E- mail: <span>info@visuals.rs</span></p>
                  <p className="contact-info-data">Phone: <span style={{ fontSize: '0.9rem' }}>+381 65 800 5131</span>
                  </p>
                  <p className="contact-info-data">Address: <span>Oblakovska 28, Belgrade</span></p>
                </div>
              </div>
              <div className="col-sm padding__5 max-width-fit-content text-right">
                <div className="contact-info text-left">
                  <p className="contact-info-title"><strong>Our services are available in <br/> countries listed
                    below</strong></p>
                  <hr/>
                  <p className="contact-info-data">
                    Austria, Bosnia ,Bulgaria, </p>
                  <p className="contact-info-data">
                    Croatia,Hungary, Montenegro,</p>
                  <p className="contact-info-data">
                    North Macedonia, Romania,Slovakia, Slovenia
                  </p>
                </div>
              </div>
            </div>
            <div className="padding__5">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe className="iframe-contact" width="1080" height="500" id="gmap_canvas" title="google map"
                          src="https://maps.google.com/maps?q=Oblakovska%2028,%20Belgrade&t=&z=13&ie=UTF8&iwloc=&output=embed"/>
                </div>
              </div>

            </div>
          </main>
        </section>
      </div>
    );
  }
}

export default ContactPage;
