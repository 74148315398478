import React from 'react';

import './assets/css/footer.css';
import logo from './assets/images/footer-logo.webp'
import { NavLink } from 'react-router-dom';


class Footer extends React.Component {

  render() {
    return (
      <footer className="footer-bg">
        <div className="container-fluid footer-container">
          <div className="row no-margin-row footer-row text-center">
            <div className="col-sm footer-col">
              <img className="logo-img" src={logo} alt="Visual Impact Logo"/>
            </div>

            <div className="col-sm wrapper-from-bottom footer-col margin-top-1vh footer-sitemap-container">
              <h1 className="footer-sitemap-title">Sitemap</h1>
              <div id="footer__nav-link" className="padding_bottom__2">
                <NavLink className="footer__nav-link" to="/truck-details">Truck Details</NavLink>
              </div>
              <div id="footer__nav-link" className="padding_bottom__2">
                <NavLink className="footer__nav-link" to="/audio-video">Audio & Video System</NavLink>
              </div>
              <div id="footer__nav-link" className="padding_bottom__2">
                <NavLink className="footer__nav-link" to="/events">Events</NavLink>
              </div>
              <div id="footer__nav-link" className="padding_bottom__2">
                <NavLink className="footer__nav-link" to="/contact">Contact</NavLink>
              </div>
            </div>
            <div className="col-sm wrapper-from-bottom footer-col margin-top-1vh footer-inquires-container">
              <div className="footer__inquires-container">
                <div className="footer__inquires-container__email-section padding_bottom__2">
                  <span>E-mail: &nbsp;</span>
                  <span><a className="footer__nav-link" href="mailto:info@visuals.rs">info@visuals.rs</a></span>
                </div>
                <div className="footer__inquires-container__phone-section">
                  <span>Phone: &nbsp;</span>
                  <span><a className="footer__nav-link" href="tel:+381658005131">+381 65 800 5131</a></span>
                </div>
              </div>
            </div>
          </div>
          <div style={{padding:'0 2%' }}>
            <hr/>
          </div>
          <div className="row no-margin-row text-center footer__last-container">
            <div className="col-sm footer-col flex-70pre wrapper-from-bottom">
              <div className="footer__copyright-container">
               <span>&copy; &nbsp; Visual Impact Group</span>
              </div>
            </div>
            <div className="col-sm footer-col wrapper-from-bottom">
              <div className="footer__social-media-container">
                <div className="row no-margin-row flex-row-reverse">
                  <div className="col-sm padding_left__2">
                    <a href="https://www.instagram.com/visualimpactserbiaob/"  target="_blank" className="fa fa-instagram footer__nav-link"/>
                  </div>
                  <div className="col-sm padding_left__2">
                    <a href="https://www.facebook.com/people/Srdjan-Rsumovic-Visual-Impact-Serbia/100010865612372/" target="_blank" className="fa fa-facebook footer__nav-link"/>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

}

export default Footer;
