import React from 'react';
import './assets/css/events.css';
import eventClientViewJson from './assets/json/event-client-view.json';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row

} from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
class EventsPage extends React.Component {

  constructor(props) {
    super(props);
    this.eventJson = eventClientViewJson.clients;
    this.state={
      isModalOpen:false, // state for the modal popup
      images: [], // images array we receive from the parent
      imagesToShow:0, // limit
      currentIndex:0, // used for the carousel
      galleryTitle: ''
    }
    this.toggleModal= this.toggleModal.bind(this)
  }

  toggleModal(){
    this.setState({
      isModalOpen:!this.state.isModalOpen
    })
  }

// used to set the current index of the carousel
  showModalImages(images, galleryTitle){
    this.setState({
      images: images,
      galleryTitle: galleryTitle
    })
    this.toggleModal();
  }
  render() {
    return (
      <div className="container-fluid disable-margin-padding-container td-main-container">
        <section id="ta-intro-section">
          <div className="events-intro-colored-container text-center">
            <h1 className="events-intro-title">
              Events
            </h1>
          </div>
          <div className="events-intro-text-container text-center">
            <h2 className="events-intro-text-container-title">Explore our projects</h2>
          </div>
          <div className="">
            <div className="row align-items-start no-margin-row no-padding-row">
              <div className="col-sm padding__5">
                <Col className="cursor-pointer" onClick={ ()=> this.showModalImages(this.eventJson[0].images,
                    this.eventJson[0].name+' - '+ this.eventJson[0].description)}>
                  <EventThumbnail data = {this.eventJson[0]}/>
                </Col>
              </div>
              <div className="col-sm padding__5">
                <Col className="cursor-pointer" onClick={ ()=> this.showModalImages(this.eventJson[1].images,
                    this.eventJson[1].name+' - '+ this.eventJson[1].description)}>
                <EventThumbnail data = {this.eventJson[1]} />
                </Col>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row align-items-start no-margin-row no-padding-row">
              <div className="col-sm padding__5">
                <Col className="cursor-pointer" onClick={ ()=> this.showModalImages(this.eventJson[2].images,
                    this.eventJson[2].name+' - '+ this.eventJson[2].description)}>
                <EventThumbnail data = {this.eventJson[2]} />
                </Col>
              </div>
              <div className="col-sm padding__5">
                <Col className="cursor-pointer" onClick={ ()=> this.showModalImages(this.eventJson[3].images,
                    this.eventJson[3].name+' - '+ this.eventJson[3].description)}>
                <EventThumbnail data = {this.eventJson[3]} />
                </Col>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row align-items-start no-margin-row no-padding-row">
              <div className="col-sm padding__5">
                <Col className="cursor-pointer" onClick={ ()=> this.showModalImages(this.eventJson[4].images,
                    this.eventJson[4].name+' - '+ this.eventJson[4].description)}>
                <EventThumbnail data = {this.eventJson[4]} />
                </Col>
              </div>
              <div className="col-sm padding__5">
                <Col className="cursor-pointer" onClick={ ()=> this.showModalImages(this.eventJson[5].images,
                    this.eventJson[5].name+' - '+ this.eventJson[5].description)}>
                <EventThumbnail data = {this.eventJson[5]} />
                </Col>
              </div>
            </div>
          </div>
          <Modal
              className="modal-xl"
              isOpen={this.state.isModalOpen}
              toggle={this.toggleModal}
          >
            <ModalHeader>{this.state.galleryTitle}</ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12">
                  <ImageCarousel images={this.state.images} currentIndex={this.state.currentIndex} />
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </section>
      </div>
    );
  }
}


function EventThumbnail(props) {
  return (
    <div className="">
      <div className="event-client-icon">
        <img src={require(props.data.icon + '')} alt={"Visual Impact Group Client"}/>
      </div>
      <hr/>
      <div className="">
        <p>{props.data.name}</p>
        <p>{props.data.description}</p>
      </div>
      <div className="display-flex">
        <div className="flex-50pre">
          <img className="event-client-thumbnail" src={require(props.data.images[0] + '')} alt={"Visual Impact Group Client Thumbnail"}/>
        </div>
        <div className="flex-50pre">
          <img className="event-client-thumbnail" src={require(props.data.images[1] + '')} alt={"Visual Impact Group Client Thumbnail"}/>
        </div>
      </div>
      <div className="display-flex">
        <div className="flex-60pre">
          <img className="event-client-thumbnail" src={require(props.data.images[2] + '')} alt={"Visual Impact Group Client Thumbnail"}/>
        </div>
        <div className="flex-40pre">
          <img className="event-client-thumbnail" src={require(props.data.images[3] + '')} alt={"Visual Impact Group Client Thumbnail"}/>
        </div>
      </div>
      <div className="display-flex">
        <div className="flex-40pre">
          <img className="event-client-thumbnail max-height-227" src={require(props.data.images[4] + '')} alt={"Visual Impact Group Client Thumbnail"}/>
        </div>
        <div className="flex-60pre">
          <img className="event-client-thumbnail max-height-227" src={require(props.data.images[5] + '')} alt={"Visual Impact Group Client Thumbnail"}/>
        </div>
      </div>
    </div>
  );
}
class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      animating: false
    };
  }
  componentDidMount() {
    this.setState({ activeIndex: 0 });
  }

  next = () => {
    const { animating, activeIndex } = this.state;
    const { images } = this.props;
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    const { animating, activeIndex } = this.state;
    const { images } = this.props;
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = newIndex => {
    const { animating } = this.state;
    if (animating) return;
    this.setState({ activeIndex: newIndex });
  };

  setAnimating = value => {
    this.setState({
      animating: value
    });
  };

  render() {
    const { images } = this.props;
    const { activeIndex } = this.state;
    const slides = images.map(image => {
      return (
          <CarouselItem
              onExiting={() => this.setAnimating(true)}
              onExited={() => this.setAnimating(false)}
              key={uuidv4()}
          >
            <div className="d-flex justify-content-center">
              <img src={require(image+'')} alt={"Visual Impact Gallery"} className="img-fluid" />
            </div>
          </CarouselItem>
      );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
        >
          <CarouselIndicators
              items={images}
              activeIndex={activeIndex}
              onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={this.previous}
          />
          <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={this.next}
          />
        </Carousel>
    );
  }
}

export default EventsPage;
