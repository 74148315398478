import React, { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './assets/css/app.css';

import  Header  from '../components/header'
import Footer from '../components/footer';
import LandingPage from '../containers/landing-page';
import TruckDetailsPage from '../containers/truck-details-page';
import AudioVideoPage from '../containers/audio-video-page';
import EventsPage from '../containers/events-page';
import ContactPage from '../containers/contact-page';
import { AnimatePresence, motion } from 'framer-motion';
import {NotFoundPage} from "../containers/not-found-page";



const App = () =>{
  const location = useLocation();
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }
  return (
    <Wrapper>
      <div className= {location.pathname==='/' ? 'background' : ''}>
        <Header/>
        <AnimatePresence mode={'wait'}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}>
        <Routes key={location.pathname} location={location}>
          <Route path="/" index element={<LandingPage/>}/>
          <Route path="/truck-details" element={<TruckDetailsPage/>}/>
          <Route path="/audio-video" element={<AudioVideoPage/>}/>
          <Route path="/events" element={<EventsPage/>}/>
          <Route path="/contact" element={<ContactPage/>}/>
          <Route path="/*" element={ <NotFoundPage/>}>
          </Route>
        </Routes>
          </motion.div>
        </AnimatePresence>
        <Footer/>
      </div>
    </Wrapper>
    );
}

export default App;
