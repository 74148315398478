import React from 'react';

import './assets/css/truck-details.css';
import './assets/css/truck-details-mobile.css';

import crossImg from './assets/images/cross.webp';
import floorPlanImg from './assets/images/floor-plan.webp';

import specsJson from './assets/json/specs.json';
import truckImagesJson from './assets/json/truck-images-info.json';

import Specs from '../../components/specs';

class TruckDetailsPage extends React.Component {

  constructor(props) {
    super(props);
    this.specs = specsJson.data;
    this.imagesInfo = truckImagesJson.data;
  }

  render() {
    return (
        <div className="container-fluid disable-margin-padding-container td-main-container">
          <section className="td-intro-section">
            <div className="td-intro-colored-container text-center">
              <h1 className="td-intro-title">
                High Definition <br/> Production Truck <br/> VI-1
              </h1>
            </div>
            <div className="td-intro-text-container text-center">
              <h2 className="td-intro-text-container-title">Explore the truck's outside and become acquainted with all
                of
                the specifications</h2>
              <br/>
              <br/>
              <img className="img-fluid" src={crossImg} alt="Visual Impact OB truck - Carrosserie Akkermans"/>
              <p className="td-intro-text-container-description"> Vehicles known as OB trucks are used to transmit all
                audio and video signals from the event to viewers and listeners. During the past few decades, the
                demands
                of external productions have increased dramatically
                changed. This, in turn, has a direct impact on the OB trucks, which are now operational
                like mini studios on wheels.</p>
            </div>
          </section>
          <section className="td-specs-section">
            <div className="row no-margin-row no-padding-row">
              <div className="col-sm padding__5">
                <Specs data={this.specs[0]}/>
              </div>
              <div className="col-sm padding__5">
                <Specs data={this.specs[1]}/>
              </div>
            </div>
          </section>
          <section className="td-floor-plan-section">
            <div className="text-center">
              <h3>Floor plan</h3>
              <div className="td-floor-plan-img-container">
                <img className="img-fluid" src={floorPlanImg} alt="Visual Impact OB Truck - Floor plan"/>
              </div>
            </div>
          </section>
          <section className="td-outside-of-truck-section">
            <div className="text-center">
              <h3>Take a look at the outside of the VI-1 truck!</h3>
              <div className="td-outside-of-truck-container">
                {this.loadThreeImages(this.imagesInfo, false, [0, 1, 2])}
                <br/>
                {this.loadOneImage(this.imagesInfo, 3)}
                <br/>
              </div>
            </div>
          </section>
        </div>
    );

  }

  //ToDo refactor logic for image paths so we can move this code for 3 images and image in one component
  loadThreeImages(data, orderFlag, indexes) {
    let imgInfo1 = data[indexes[0]];
    let imgInfo2 = data[indexes[1]];
    let imgInfo3 = data[indexes[2]];
    return (
        <div className="row no-margin-row no-padding-row">
          <div
            className={orderFlag ? 'col-sm left-image-container img-col-order max-height-85vh' : 'col-sm left-image-container max-height-85vh'}>
            <img className="img-fluid height__100 width-100vw mobile_padding-bottom-2 bored-radius-35px" src={require(imgInfo1['imgPath'] + '')}
                 alt={imgInfo1['imgAlt']}/>
          </div>
          <br/>
          <div className={orderFlag ? 'col-sm padding_right__2' : 'col-sm padding_left__2'}
               style={orderFlag ? { textAlign: 'right' } : {}}>
            <img className="img-fluid width__100 height__50 padding_bottom__2 bored-radius-35px" src={require(imgInfo2['imgPath'] + '')}
                 alt={imgInfo2['imgAlt']}/>
            <br/>
            <img className="img-fluid width__100 height__50 padding_bottom__2 bored-radius-35px" src={require(imgInfo3['imgPath'] + '')}
                 alt={imgInfo3['imgAlt']}/>
          </div>
          <br/>
        </div>
    );
  }

  loadOneImage(data, index) {
    return (
      <div className="img-container text-center">
        <img className="img-fluid width__100 bored-radius-35px max-height-65vh" src={require(data[index]['imgPath'] + '')} alt={data[index]['imgAlt']}/>
      </div>
    );
  }

}

export default TruckDetailsPage;
