
import React from 'react';
import {NavLink} from "react-router-dom";
import './assets/css/not-found.css'
export const NotFoundPage = () =>{

    return (
        <div className="not-found-main-container">
            <div className="text-center min-vh-50">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Page not found.</p>
                <p className="lead">
                    The page you’re looking for doesn’t exist.
                </p>
                <NavLink className="btn btn-primary" to={'/'}>Go Home</NavLink>
            </div>
        </div>
    )
}