import React  from 'react';
import logo from './assets/images/logo.webp';
import './assets/css/header.css';
import { NavLink } from 'react-router-dom';

const Header = (props) => {

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-bg">
        <div className="container-fluid">
          <div className="nav-bar-logo">
            <NavLink to={'/'}>
              <img className="navbar-brand header-logo-size" src={logo} alt="Visual Impact OB Truck Logo"/>
            </NavLink>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav ms-auto mb-2 mb-lg-0 nav-bar-items-container">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item nav-bar-items-spacing">
                  <NavLink className="nav-link" aria-current="page" to="/truck-details">Truck Details</NavLink>
                </li>
                <li className="nav-item nav-bar-items-spacing">
                  <NavLink className="nav-link" to={'/audio-video'}>Audio & Video System</NavLink>
                </li>
                <li className="nav-item nav-bar-items-spacing">
                  <NavLink className="nav-link" to={'/events'}>Events</NavLink>
                </li>
                <li className="nav-item nav-bar-items-spacing">
                  <NavLink className="nav-link" to={'/contact'}>Contact</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );

};

export default Header;
