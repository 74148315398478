import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../reducers/AppReducer'
const loggerMiddleware = createLogger();

export const store = configureStore({
  reducer: {
    app: appReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
});
