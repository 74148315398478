import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import Specs from '../../components/specs';


import './assets/css/audio-video.css';
import './assets/css/audio-video-mobile.css';

import specsJson from './assets/json/specs.json'
import imagesInfoJson from './assets/json/in-truck-images-info.json'


class AudioVideoPage extends React.Component {

  constructor(props) {
    super(props);
    this.videoSpecs=specsJson.videoSpecs
    this.audioSpecs=specsJson.audioSpecs
    this.imagesInfo=imagesInfoJson.data
  }

  render() {
    return (
      <div className="container-fluid disable-margin-padding-container td-main-container">
        <section className="ta-intro-section">
          <div className="av-intro-colored-container text-center">
            <h1 className="av-intro-title">
              Audio & Video
            </h1>
          </div>
          <div className="av-intro-text-container text-center">
            <h2 className="av-intro-text-container-title">Explore the truck's inside and become acquainted with all of the specifications</h2>
          </div>
        </section>
        <section className="av-specs-section">
          <div className="row no-padding-row no-margin-row padding_0_10">

            <div id="video-col" className="col-sm">
              <div className="text-center">
                <h2 className="av-sub-title">Video System Specifications</h2>
              </div>
              {this.videoSpecs.map(spec =>{
                return (
                  <div className="padding_0_5" key={uuidv4()}>
                    <Specs data={spec} />
                  </div>
                )
              })}
            </div>
            <div id="audio-col" className="col-sm">
              <div className="av-sub-title text-center">
                <h2 className="av-sub-title">Audio System Specifications</h2>
              </div>
              {this.audioSpecs.map(spec =>{
                return (
                  <div className="padding_0_5"  key={uuidv4()}>
                    <Specs data={spec} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="av-images-section">
          <div className="text-center">
            <h2>Take a look at the inside of the VI-1 truck!</h2>
          </div>
          <div className="td-outside-of-truck-container">
            {this.loadThreeImages(this.imagesInfo,false,[0,1,2])}
            <br/>
            {this.loadOneImage(this.imagesInfo,3)}
            <br/>
            {this.loadThreeImages(this.imagesInfo,false,[4,5,6])}
          </div>
        </section>
      </div>
        )
  }


  //ToDo refactor logic for image paths so we can move this code for 3 images and image in one component
  loadThreeImages(data,orderFlag,indexes) {
    let imgInfo1 = data[indexes[0]];
    let imgInfo2 = data[indexes[1]];
    let imgInfo3 = data[indexes[2]];
    return (
      <div className="row no-margin-row no-padding-row">
        <div className={orderFlag?"col-sm left-image-container img-col-order max-height-80vh":"col-sm left-image-container text-right max-height-80vh"}>
          <img className="img-fluid height__100 width-100vw mobile_padding-bottom-2 bored-radius-35px" src={require(imgInfo1['imgPath'] + '')} alt={imgInfo1['imgAlt']}/>
        </div>
        <div className={orderFlag?"col-sm padding_right__2 max-height-80vh":"col-sm padding_left__2 max-height-80vh"} style={orderFlag?{textAlign:'left'}:{}}>
          <img className="img-fluid width__100 height__50 padding_bottom__2 bored-radius-35px"  src={require(imgInfo2['imgPath'] + '')} alt={imgInfo2['imgAlt']}/>
          <br/>
          <img className="img-fluid width__100 height__50 bored-radius-35px" src={require(imgInfo3['imgPath'] + '')} alt={imgInfo3['imgAlt']}/>
        </div>
      </div>
    );
  }

  loadOneImage(data,index) {
    return (
      <div className="img-container text-center">
        <img className="img-fluid width__100 bored-radius-35px max-height-65vh" src={require(data[index]['imgPath'] + '')} alt={data[index]['imgAlt']}/>
      </div>
    )
  }
}

export default AudioVideoPage
