import './assets/css/landing-page.css';
import './assets/css/landing-page-mobile.css';
import React, { useEffect, useRef } from 'react';
import landingPageImage from './assets/images/ob-truck-home.webp';
import clientIconsJson from './assets/json/client-icons.json';
import peopleIcon from './assets/images/icons/people.webp';
import truckIcon from './assets/images/icons/truck.webp';
import electricityIcon from './assets/images/icons/electricity.webp';
import explorerTruckImg from './assets/images/explorer/truck.webp';
import explorerAudioAndVideoImg from './assets/images/explorer/audioandvideo.webp';
import explorerEventsImg from './assets/images/explorer/events.webp';
import mapImg from './assets/images/map.png';
import { NavLink } from 'react-router-dom';
import { animate, motion, useInView } from 'framer-motion';


class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.clientIcons = clientIconsJson.data;
    this.state = {
      isLoaded: false,
      isTruckTextInView: false,
      isAvTextInView: false,
      isEventsTextInView: false,
      isCounterInView: false,
      xTransition: 300
    };
    this.landingPageImg = new Image().src = landingPageImage;
  }

  componentDidMount() {
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw < 576) {
      this.setState({ xTransition: 100 });
    }
  }

  render() {
    return (

        <div className="container-fluid disable-margin-padding-container overflow-x-hidden">
          <section className="lp-section-main">
            <div className="row no-margin-row no-padding-row">
              <div className="col-sm text-order wrapper-from-bottom flex-50pre">
                <motion.div
                  initial={{ x: -300 }}
                  animate={
                    { x: 0 }
                  }
                  transition={{ duration: 1.7, delay: 0 }}
                  viewport={{ once: true }}
                >
                  <div className="lp-left padding-left-15pre padding_right__2">
                    <div className="lp-heading-container">
                      <div className="lp-heading-text">
                        <h1 className="lp-heading-title">Vi-1 High Definition <br className="title-break"/>
                          Outside Broadcast Vehicle </h1>
                        <h2 className="lp-heading-desc">We provide first-rate service for capturing and broadcasting a
                          variety of events, such as sports matches, concerts, festivals and academic events.</h2>
                      </div>
                      <NavLink to='/contact'>
                        <button type="button" className="btn btn-primary lp-btn-primary">Book now</button>
                      </NavLink>

                      <div className="lp-heading-inquires">
                        <p className="lp-heading-inquires-title"><strong>For inquires contact us at:</strong></p>
                        <hr/>
                        <p className="lp-heading-inquires-data">E- mail: <span>info@visuals.rs</span></p>
                        <span className="lp-heading-inquires-data">Phone: <span style={{ fontSize: '0.9rem' }}>+381 65 800 5131</span></span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
              <div className="col-sm image-order wrapper-from-bottom flex-50pre">
                <div className="lp-container lp-right">
                  <motion.div
                    initial={{ x: -300 }}
                    animate={
                      { x: 0 }
                    }
                    transition={{ duration: 1.7, delay: 0 }}
                    viewport={{ once: true }}
                  >
                    <img className="img lp-image" src={this.landingPageImg} alt="Visual Impact OB Truck - Truck"/>
                  </motion.div>
                </div>
              </div>
            </div>
          </section>
          <section className="lp-section-counter">
            <div className="lp-clients-container">
              <div className="lp-clients-heading">
                <span className="clients-title-font">Our trusted clients</span>
              </div>
              <div className="row no-margin-row client-row">
                {this.clientIcons.slice(0, 7).map((icon) => {
                  return <ClientIcon name={icon['name']} key={icon['name']} src={icon['imgPath']}
                                     alt={icon['imgAlt']}/>;
                })}
              </div>
              <div className="row no-margin-row client-row">
                {this.clientIcons.slice(7, 14).map((icon) => {
                  return <ClientIcon name={icon['name']} key={icon['name']} src={icon['imgPath']}
                                     alt={icon['imgAlt']}/>;
                })}
              </div>
            </div>
            <div className="lp-counter-container">
              <div className="row no-margin-row">
                <div className="col-sm counter-col">
                  <div className="col-years-value-font">
                    <Counter from={0} to={30} duration={1}/>
                  </div>
                  <p className="col-years-text-font">Years on the market</p>
                </div>
                <div className="col-sm counter-col">
                  <div className="col-years-value-font"><Counter from={0} to={250} duration={1}/></div>
                  <p className="col-years-text-font">Globally dispersed clients</p>
                </div>
                <div className="col-sm counter-col">
                  <div className="col-years-value-font"><Counter from={0} to={10} duration={0.7}/></div>
                  <p className="col-years-text-font">Offices across countries</p>
                </div>
              </div>
              <div className="lp-counter-desc-container">
                <p className="lp-counter-desc">
                  The needs of outdoor productions have shifted dramatically over the last few decades. This has a
                  direct
                  impact on OB trucks, which are now mini studios on wheels that are fully equipped with TV production
                  hardware and can accommodate the entire crew. OB trucks are frequently used for live television
                  production, all audio, video, special effects, graphics, and commentary must be properly recorded,
                  processed, and distributed.
                </p>
              </div>
              <div className="row no-margin-row">
                <div className="col-sm counter-col">
                  <div className="lp-custom-icon-container">
                    <img className="lp-custom-icon" src={peopleIcon} alt="Visual Impact Ob Truck - People icon"/>
                    <div className="lp-custom-icon-title">
                      <p>The Team</p>
                    </div>
                  </div>
                  <div className="lp-custom-icon-description">
                    <p>The company can provide a complete technical and production team. It consists of a Main video
                      engineer, an audio engineer and the necessary number of audio and video assistants (riggers).</p>
                  </div>
                </div>
                <div className="col-sm counter-col">
                  <div className="lp-custom-icon-container">
                    <img className="lp-custom-icon" src={truckIcon} alt="Visual Impact Ob Truck - Truck icon"/>
                    <div className="lp-custom-icon-title">
                      <p>Rigid Truck</p>
                    </div>
                  </div>
                  <div className="lp-custom-icon-description">
                    VI-1 is an HD, full size, single expanding unit that
                    can cater for 14 cabled cameras along with multiple radio
                    cameras.
                    The unit is ideal for city centre working, music and events
                    coverage, news broadcasts and sports events.
                  </div>
                </div>
                <div className="col-sm counter-col">
                  <div className="lp-custom-icon-container">
                    <img className="lp-custom-icon" src={electricityIcon}
                         alt="Visual Impact Ob Truck - Electricity icon"/>
                    <div className="lp-custom-icon-title">
                      <p>Power and Electricity</p>
                    </div>
                  </div>
                  <div className="lp-custom-icon-description">
                    In the event of a power outage, the truck has its own UPS (uninterruptible power supply) with a
                    15-minute autonomy.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="lp-section-explorer">
            <div className="lp-explorer-container">
              <div className="lp-explorer-heading">
                <p className="lp-explorer-heading-text">Explore the inside of our rigid truck, as well as the audio and
                  video equipment
                  and our most recent projects!</p>
              </div>
              <div className="lp-explorer-truck-container">
                <div className="row no-margin-row lp-explorer-row">
                  <div className="col-sm lp-explorer-card-holder flex-50pre">
                    <div className="row no-margin-row">
                      <div className="col-sm card lp-card-overlay">
                        <img src={explorerTruckImg} className="lp-card-img"
                             alt="Visual Impact OB Truck - OB Truck"/>
                        <div className="card-img-overlay text-center">
                          <h5 className="card-title lp-card-title">Vi-1 Rigid Truck</h5>
                        </div>
                      </div>
                      <div className="col-sm lp-card lp-card-position-left lp-card-ry-bg"/>
                    </div>
                  </div>

                  <div className="col-sm flex-50pre">
                    <motion.div
                      initial={{ x: 0 }}
                      animate={this.state.isTruckTextInView ?
                        { x: 0 } : { x: this.state.xTransition }
                      }
                      transition={{ duration: 1.7, delay: 0 }}
                      viewport={{ once: true }}
                      onViewportEnter={() => this.setState({ isTruckTextInView: true })}
                    >
                      <div className="lp-explorer-text-holder">
                        <h1 className="lp-explorer-text-holder-heading">
                          Coachwork designed by Akkermans Carroserie
                        </h1>
                        <h2 className="lp-explorer-text-holder-heading-desc">
                          Examine the outside and full specifications of the VI-1 OB truck, which features bespoke
                          coachwork
                          from one of the greatest coaching (Vehicle Customization & Modification) businesses.
                        </h2>
                        <NavLink to={'/truck-details'}>
                          <button type="button" className="btn btn-primary lp-btn-primary find-out-btn">Find out more
                          </button>
                        </NavLink>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div className="row no-margin-row lp-explorer-row">
                  <div className="col-sm audio-video-text-col flex-50pre lp-av-order-2">
                    <motion.div
                      initial={{ x: 0 }}
                      animate={this.state.isAvTextInView ?
                        { x: 0 } : { x: -this.state.xTransition }
                      }
                      transition={{ duration: 1.7, delay: 0 }}
                      viewport={{ once: true }}
                      onViewportEnter={() => this.setState({ isAvTextInView: true })}
                    >
                      <div className="lp-explorer-text-holder">
                        <h1 className="lp-explorer-text-holder-heading">
                          Bespoke solution for a wide range of uses
                        </h1>
                        <h2 className="lp-explorer-text-holder-heading-desc">
                          Check out the full equipment specifications for both audio and video, We offer a variety of
                          cameras, lenses, and other accessories.
                        </h2>
                        <NavLink to={'/audio-video'}>
                          <button type="button" className="btn btn-primary lp-btn-primary find-out-btn">Find out more
                          </button>
                        </NavLink>
                      </div>
                    </motion.div>
                  </div>
                  <div className="col-sm lp-explorer-card-holder flex-50pre lp-av-order-1">
                    <div className="row no-margin-row">
                      <div className="col-sm lp-card lp-card-position-right lp-card-bbl-bg"/>
                      <div className="col-sm card lp-card-overlay">
                        <img src={explorerAudioAndVideoImg} className="lp-card-img"
                             alt="Visual Impact OB Truck - OB Truck Audio and Video"/>
                        <div className="card-img-overlay text-center">
                          <h5 className="card-title lp-card-title">Audio and Video</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-margin-row lp-explorer-row">
                  <div className="col-sm lp-explorer-card-holder flex-50pre">
                    <div className="row no-margin-row">
                      <div className="col-sm card lp-card-overlay">
                        <img src={explorerEventsImg} className="lp-card-img"
                             alt="Visual Impact OB Truck - OB Truck Our Events"/>
                        <div className="card-img-overlay text-center">
                          <h5 className="card-title lp-card-title">Events</h5>
                        </div>
                      </div>
                      <div className="col-sm lp-card lp-card-position-left lp-card-pb-bg"/>
                    </div>
                  </div>
                  <div className="col-sm flex-50pre">
                    <motion.div
                      initial={{ x: 0 }}
                      animate={this.state.isEventsTextInView ?
                        { x: 0 } : { x: this.state.xTransition }
                      }
                      transition={{ duration: 1.7, delay: 0 }}
                      viewport={{ once: true }}
                      onViewportEnter={() => this.setState({ isEventsTextInView: true })}
                    >
                      <div className="lp-explorer-text-holder">
                        <h1 className="lp-explorer-text-holder-heading">
                          We participate in various types of events
                        </h1>
                        <h2 className="lp-explorer-text-holder-heading-desc">
                          External broadcasts and transmissions are utilized for a variety of activities that do not
                          take
                          place in a studio and, as a result, necessitate the employment of a mobile radio/television
                          studio
                          or control room
                        </h2>
                        <NavLink to={'/events'}>
                          <button type="button" className="btn btn-primary lp-btn-primary find-out-btn">Find out more
                          </button>
                        </NavLink>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>

          </section>
          <section className="lp-section-map">
            <div className="lp-map-container text-center">
              <h1 className="lp-map-heading">With our base in Serbia, our services are also offered in the countries
                indicated below.</h1>
              <img className="img-fluid" src={mapImg} alt="Visual Impact OB Truck covered area"/>
            </div>
          </section>
        </div>)
      ;
  }


}


function ClientIcon(props) {
  return <div className="col-sm client-col">
    <img
      className={props.name === 'EXIT' ? 'client-img client-img-height-auto margin-top-1_5vh' : 'client-img client-img-height'}
      src={require(props.src + '')} alt={props.alt}/>
  </div>;
}

function Counter({ from, to, duration }) {
  const ref = useRef();
  const isInView = useInView(ref, { once: true });
  useEffect(() => {
    const controls = animate(from, to, {
      duration: duration,
      onUpdate(value) {
        ref.current.textContent = Math.round(value.toFixed(1)) + '+';
      },
    });
    return () => controls.stop();
  }, [from, to, isInView]);

  return <p ref={ref}/>;
}

export default LandingPage;
