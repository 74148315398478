import { createSlice } from '@reduxjs/toolkit'



export const appSlice = createSlice({
  name: 'app',
  initialState: {
    value: {}
  },
  reducers:{
    printState: (state) =>{
      console.log(state)
    }
  }
})

export const {printState} = appSlice.actions
export default appSlice.reducer
