import React from 'react';
import './assets/css/specs.css'
import { v4 as uuidv4 } from 'uuid';
const Specs = (props) =>{
  return (
    <div className="specs-container">
      <div className="specs-title">
        <h5 className="specs-title-heading">
          {props.data.title}
        </h5>
      </div>
      <br/>
      {
        props.data.specs.map(spec => {
          return (
            <div key={spec.key+uuidv4()}>
              <div className="spec-container row no-margin-row no-padding-row">
                <div className="col-sm spec-key">{spec.key}</div>
                <div className="col-sm spec-value">{spec.value}</div>
              </div>
              <hr className="spec-hr"/>
            </div>

          );
        })
      }
    </div>
  );
}

export default Specs;
