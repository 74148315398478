import axios from 'axios';



export async function validateToken(token){
  let url = `https://api.visuals.codebusters.org.uk:8087/v1/recaptcha/verify/${token}`;
  const headers = {
    'accept': '*/*',
  };
  return axios({
    method: 'get',
    url: url,
    data: null,
    headers: headers,
  }).then(response => {
    if(response.status===200 && response.data.success){
      return Promise.resolve()
    }
      return Promise.reject(response)
  });
}


export async function sendInquiries(payload) {
  let url = `https://api.visuals.codebusters.org.uk:8087/v1/ob-truck/book-now`;
  const headers = {
    'accept': 'application/json',
    'content-type': 'application/json'
  };
  return axios({
    method: 'post',
    url: url,
    data: payload,
    headers: headers,
  }).then(response => {
    if(response.status===200){
      return Promise.resolve()
    }
    return Promise.reject(response)
  })
}
